import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { apiUrl } from "../config";
import useAxios from "../utils/useAxios";
import Form from 'react-bootstrap/Form';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';
import UploadFinished from "../components/UploadFinished";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUpload, faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import BadFormat from "../components/BadFormat";
import React from "react";
import ReactGA from "react-ga";




//const chunkSize = 1024;
 const chunkSize = 1024 * 1024 * 3;

function ChunkedUpload() {
  const [formValidated, setFormValidated] = useState(false);
  const [uploading, setUploading] = useState(false)
  const [email, setEmail] = useState("")
  const [taskId, setTaskId] = useState("")
  const [counter, setCounter] = useState(1)
  const [fileToBeUpload, setFileToBeUpload] = useState({})
  const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0)
  const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize)
  const [progress, setProgress] = useState(0)
  const [fileGuid, setFileGuid] = useState("")
  const [backendFileHandle, setBackendFileHandle] = useState("")
  const [fileSize, setFileSize] = useState(0)
  const [chunkCount, setChunkCount] = useState(0)
  const api = useAxios();
  const [showModal, setShowModal] = useState(false);
  const [fileName, setFileName] = useState("");
  const [inputFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const MAX_FILE_SIZE = 12 * 1024 * 1024 * 1024;
  const [submitCount, setSubmitCount] = useState(0);
  const [lastSubmitTime, setLastSubmitTime] = useState(0);


  useEffect(() => {
    if (counter <=   chunkCount) {
      if (chunkCount === 1) {
        uploadFileFull();
      }
      else{
        uploadFileChunked(counter);
      }
    }
  }, [fileToBeUpload, progress])

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const file = event.currentTarget[1].files[0]
    const filename = file.name;
    const email = event.currentTarget[0].value;
    const extension = filename.split('.').pop();
    const allowedExtensions = ['blend'];
    const now = new Date().getTime();
    

    if (submitCount >= 5 && now - lastSubmitTime < 600000) {
      setErrorMessage('Form submission limit exceeded. Please try again in a hour.');
      console.log('Form submission limit exceeded. Please try again in a hour.');
      return;
    }

    if (!validateEmail(email)) {
      console.log('email address is invalid');
      setErrorMessage('Please provide a valid email address.');
      event.stopPropagation();
    }
    else if (form.checkValidity() === false) {
      event.stopPropagation();
    } else if (!allowedExtensions.includes(extension)){
      console.log("wrong file format, .blend expected, got " + JSON.stringify(extension));
      setErrorMessage('Invalid file format. Please select a .blend file.');
      event.stopPropagation();
    } else if (file.size > MAX_FILE_SIZE) { 
      console.log("file size too large, maximum allowed size is 12GB");
      setErrorMessage('File size too large, maximum allowed size is 12GB');
      event.stopPropagation();
    }
    else {
      getFileContext(event);
    }

    setFormValidated(true);
    setSubmitCount(submitCount + 1);
    setLastSubmitTime(now);
  };
  
  const validateEmail = (email) => {
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  };

  const uploadFileChunked = () => {
    setCounter(counter + 1);
    if (counter <= chunkCount) {
      var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
      uploadChunk(chunk)
    }
  }

  const resetChunkProperties = () => {
    setUploading(true)
    setProgress(0)
    setCounter(1)
    setBeginingOfTheChunk(0)
    setEndOfTheChunk(chunkSize)
  }

  const getFileContext = (e) => {
    setUploading(false)
    resetChunkProperties();
    const _file = e.currentTarget[1].files[0];
    setFileSize(_file.size)
    const _totalCount = _file.size % chunkSize === 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
    setEmail(e.currentTarget[0].value)
    setChunkCount(_totalCount)
    setFileToBeUpload(_file)
    const _fileID = uuidv4() + "." + _file.name.split('.').pop();
    setFileGuid(_fileID)
    setBackendFileHandle("")
    setFileName(e.currentTarget[1].files[0].name);
  }

  const makePutPayload = (chunk) => {
    const formData = new FormData()
    formData.append("file", chunk)
    formData.append("filename", fileGuid)
    return formData
  }

  const uploadChunk = async (chunk) => {
    try {
      const put_payload = makePutPayload(chunk)
      const url = apiUrl + "/api/v1/upload/" + backendFileHandle

      const response = await api.put(url, put_payload, {
        headers: { 
          'Content-Type': 'multipart/form-data', 
          'Content-Range': `bytes ${beginingOfTheChunk}-${endOfTheChunk - 1}/${fileSize}`,
        }
      });
      if (response.status === 200) {
        const data = response.data;
        setBackendFileHandle(data.id)
        setBeginingOfTheChunk(endOfTheChunk);
        setEndOfTheChunk(Math.min(endOfTheChunk + chunkSize, fileSize));
        if (counter === chunkCount) {
          await uploadCompleted();
        } else {
          const percentage = (counter / chunkCount) * 100;
          setProgress(percentage);
        }
      } else {
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const uploadCompleted = async () => {
    const formData = new FormData();
    formData.append("email", email)
    const response = await api.post(apiUrl + "/api/v1/upload/" + backendFileHandle, formData, {});
    handleUploadCompleted(response)
  }

  const uploadFileFull = async () => {
    const formData = makePutPayload(fileToBeUpload);
    formData.append("email", email)
    const response = await api.post(apiUrl + "/api/v1/upload/", formData, {});
    handleUploadCompleted(response)
    setCounter(counter + 1)
  }

  const handleUploadCompleted = (response) => {
    setUploading(false)
    if (response.status === 200) {
      setProgress(100);
      setTaskId(response.data["uuid"])
      setShowModal(true);
    }
  }

  const StyledProgressBar = styled(ProgressBar)`
    width: 500px;
    height: 25px;
  `;

  const getProgressBar = () => {
    if (progress !== 100) {
      
        return <StyledProgressBar animated now={progress} label={`${Math.round(progress)}%`} width={300} height={30} />;
   
    }
    else {
     
        return <StyledProgressBar striped variant="success" now={progress} label={`${Math.round(progress)}%`} />;
     
    }
  }
  

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
  flex-direction: column;
  border: 4px dotted #495579;
  background-color: #E8E8E8;
  margin: 15px;
  box-sizing: border-box;
  border-radius: 15px;

  @media only screen and (max-width: 768px) {
    height: auto;
    min-height: 400px;
  }
  
`;

const InputGroup = styled(Form.Group)`
  display: flex;
  align-items: center;
  position: relative;
  width: 600px;
  
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

  const Input = styled(Form.Control)`
  height: 60px;
  padding-left: 1rem;
  padding-right: 1rem;
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #495579;
  font-size: 1.1rem;

  &:focus {
    box-shadow: 0 0 5px rgba(234, 118, 0, 0.5);
  }
`;

const FileInput = styled(Input)`
  display: none;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  
`;


  
const FileInputLabel = styled.label`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 60px;
  background-color: #495579;
  color: #fff;
  text-align: center;
  font-size: 1.3rem;
  line-height: 60px;
  border-radius: 10px;
  
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 50px;
    font-size: 1rem;
    line-height: 50px;
  }
`;

  
  const Title = styled.h1`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #183153;
  margin-top: 60px;

  @media only screen and (max-width: 768px) {
    margin-top: 30px;
  }
`;  

  
const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: 400px;
  height: 50px;
-webkit-box-shadow: 0px 4px 3px 3px rgba(66, 68, 90, 1);
-moz-box-shadow: 0px 4px 3px 3px rgba(66, 68, 90, 1);
box-shadow: 0px 4px 3px 3px rgba(66, 68, 90, 1);

  &:hover {
    background-color: #0069d9;
    -webkit-box-shadow: 0px 4px 3px 0px rgba(66, 68, 90, 1);
-moz-box-shadow: 0px 4px 3px 0px rgba(66, 68, 90, 1);
box-shadow: 0px 4px 3px 0px rgba(66, 68, 90, 1);
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
  

  const ButtonWrapper = styled.div`
    padding-top: 30px;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 768px) {
    padding-top: 20px;
  }
  
`;  
  
  const Blender = styled.img`
    height:60px;

    @media only screen and (max-width: 768px) {
    height: 40px;
  }
  `;  
  
  const StyledFileInputLabel = styled(FileInputLabel)`
      
    :hover {
      background-color: #68718e;
    }
  `;
  
  

    return (
    <Container>
        <Title>Upload your <Blender src="./img/blender_logo.png" /> project</Title>
        <FontAwesomeIcon icon={faCloudArrowUp} size="8x"color="#495579"/>
      <Row className="mt-2">
        <Form noValidate validated={formValidated} onSubmit={handleSubmit}>
            
            

            <Form.Group className="mb-5" as={Row} style={{ display: uploading ? "none" : "block" }}>
              <InputGroup controlId="emailControl" className="mb-6 mt-5" as={Row}>
              <Form.Label>
                <FontAwesomeIcon icon={faEnvelope} /> Email address
              </Form.Label>
              <Input type="email" placeholder="enter your email address" required disabled={uploading} />
              
              <Form.Text className="text-muted">
                You'll receive an emain when your render will be ready
              </Form.Text>
            </InputGroup>


            
         
          <InputGroup controlId="fileUploadControl" className="mb-5" as={Row}>
            <FileInput name='file' type="file" accept=".blend" id="fileInput" required disabled={uploading} />
                <StyledFileInputLabel htmlFor="fileInput">{!inputFile ?  "Choose a .blend file to upload" : {fileName}}</StyledFileInputLabel>
          </InputGroup>
              <ButtonWrapper>
                <Button type="submit">Start rendering!</Button>
              </ButtonWrapper>
              {errorMessage && <BadFormat message={errorMessage} />}
          </Form.Group>
          <Form.Group style={{ display: uploading ? "block" : "none" }}>
              <h4 style={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>Uploading...</h4>
              <h5>{fileName}</h5>
              {getProgressBar()}
            </Form.Group>
            
        </Form>
      </Row>
        <UploadFinished display={progress === 100} email={email} taskId={taskId} show={showModal}
        onHide={() => setShowModal(false)}/>
        
    </Container>
  );
}

export default ChunkedUpload;