import Alert from 'react-bootstrap/Alert';
import styled from "styled-components";


const StyledAlert = styled(Alert)`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
`;

function BadFormat(props) {

    return (
        <StyledAlert variant="danger">
            <Alert.Heading>Wrong file format</Alert.Heading>
            <p>{props.message}</p>
        </StyledAlert>
    );
}

export default BadFormat