import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Section = styled.div`
  padding-top: 60px;
`

const FaqContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding-left: 50px;

  @media (max-width: 768px) {
  }
`;

const Question = styled.h3`
  font-size: 18px;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Answer = styled.p`
  font-size: 15px;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #183153;
  opacity: ${props => props.show ? 1 : 0};
  max-height: ${props => props.show ? '1000px' : '0px'};
  overflow: hidden;
  transition: all 0.3s ease-out;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const FaqSection = styled.div`
  margin-bottom: 10px;
`;

const Title = styled.h2`
  font-size: 48px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #183153;
  border: 2px solid #ccc;
  border-radius: 10px;
  
  border-bottom: 3px solid #bfbfbf;
  padding-bottom: 10px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 32px;
  }

`;

function Faq() {
  const faqs = [
    {
      question: "- How this work?",
    answer:
      "Brender.it is an application with which you can render your projects created in Blender. We use a computer with the best components, so rendering is done in a short time. The process is simple. Just send the file to know the price and time of the service.",
  },
  {
    question: "- Pricing",
    answer:
      "No unexpected costs. The price of the service depends on the length of the rendering time. The more frames or samples the project contains, the higher the cost. To know the price, send your project using the form.",
  },
  {
    question: "- You didn't receive an email?",
    answer:
      "You sent a file but did not receive an email with a quote? apparently the server is busy. Your project has been queued. You will receive a message as soon as the server is free.",
  },
  {
    question: "- Render quality",
    answer:
      "If you are not satisfied with the quality of the render, check the project settings in Blender again. All rendering settings are saved in the .blend file you send to us. We do not change them, we use the settings declared by you.",
    },
  {
    question: "- How to render single image",
    answer:
      "Select the frame range accordingly. If you are going to render only one frame in the settings you should set the first and last frame to the same frame. Don't forget to select the format you want the image to be rendered in.",
    },
  {
    question: "- How to render single animation",
    answer:
      "Select the frame range accordingly. If you're only going to render a multi-frame animation, you should set a frame range in the settings. Don't forget to select the format you want the image to be rendered in..",
    },
  {
    question: "- I would like to use a different engine than cycles",
    answer:
      "Brender.it only renders using the Cycles engine, but if you have a larger project that you would like to render using another engine e.g. Eevee, please contact us using the form. We will be happy to help.",
    },  
  {
    question: "- I have a custom project to render or brender.it app is not working",
    answer:
      "Sometimes something goes wrong and the server is down. Contact us if you still have any questions. Also feel free to contact me if you have an unusual project to render.",
    }
  ];

  const [activeIndex, setActiveIndex] = React.useState(null);

  const handleQuestionClick = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <Container>
      <Section>
        <FaqContainer>
          <Title>Frequently Asked Questions</Title>
          {faqs.map((faq, index) => (
            <FaqSection key={index}>
              <Question onClick={() => handleQuestionClick(index)}>
                {faq.question}
              </Question>
              <Answer show={activeIndex === index}>{faq.answer}</Answer>
            </FaqSection>
          ))}
        </FaqContainer>
      </Section>
    </Container>  
  );
}

export default Faq;
