import React from 'react';
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faYoutube, faLinkedin, faCcVisa, faCcMastercard, faPaypal } from "@fortawesome/free-brands-svg-icons";


const Section = styled.div`
  display: flex;
  justify-content: center;
`


const Container = styled.div`
  background-color: #20232A;
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: start;

  @media only screen and (max-width: 768px) {
    padding-top: 80px;
    height: 250px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
`;

const Left = styled.div`
  flex: 1;
  color: #FFFBEB;
  font-size: 16;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const LeftContainer = styled.div`
  width: 100%;
  flex: 4;
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
`;

const LeftBottomDiv = styled.div`
  flex: 1;
  height: 50px;
  margin: 0 0 10px 0;
  align-self: center;
`;

const LeftLeftDiv = styled.div`
 flex: 1;
 color: #FFFBEB;
 font-size: 12px;
 margin: 20px 20px 0 20px;

 @media only screen and (max-width: 768px) {
    font-size: 10px;
    margin: 10px;
    text-align: center;
  }
`;

const LeftRightDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
`;

const Link = styled.a`
  color: #868686;
`;


const Right = styled.div`
  flex: 1;
  color: #FFFBEB;
  font-size: 16;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const RightContainer = styled.div`
  width: 100%;
  flex: 4;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const RightLeftDiv = styled.div`
 flex: 1;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: row;
  justify-content: space-around;

  @media only screen and (max-width: 768px) {
    padding-top: 10px;
  }
`;

const RightRightDiv = styled.div`
  flex: 2;
  color: #FFFBEB;
  font-size: 13px;
  text-align: center;
  letter-spacing: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  @media only screen and (max-width: 768px) {
    font-size: 10px;
    margin: 10px;
    text-align: center;
  }
  
  
`;

const Icon = styled(FontAwesomeIcon)`
  color: #FFFBEB;
  font-size: 26px;

  &:hover {
    font-size: 28px;
    color: #b4b4ff;
    font-size: 26px;
  }
`;

const IconLeft = styled(FontAwesomeIcon)`
  color: #FFFBEB;
  font-size: 20px;
  padding-left: 20px;
  margin-top: 10px;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
    padding-left: 10px;
    margin-top: 5px;
  }

  &:hover {
    color: #b4b4ff;
  }

  
`;

const Img = styled.img`
  height: 26px;
  background-color: white;
  margin-left: 10px;
`;



function Footer() {
  return (
    <Section>
      <Container>
        <Left>
          <LeftContainer>
            <LeftLeftDiv>
              <b>BRENDER.IT</b> is an online application designed for Blender graphic designers to easily render their projects. With brender.it, users can simply upload their .blend files and have them rendered quickly and efficiently. This powerful tool is ideal for artists, designers, and animators who want to save time and resources while still achieving high-quality results. So if you're looking for a fast and efficient way to render your Blender projects, <b>brender.it</b> is the perfect solution.
            </LeftLeftDiv>
            <LeftRightDiv>
              LINKS:
              <Link href="/terms">Terms of use</Link>
              <Link href="/privatypolicy">Privaty Policy</Link>
            </LeftRightDiv>
            
          </LeftContainer>
          <LeftBottomDiv>
            Payments powered by
            <Img src="./img/stripe.png" />
            <IconLeft icon={faCcVisa} />
            <IconLeft icon={faCcMastercard} />
            <IconLeft icon={faPaypal} />
          </LeftBottomDiv>
        </Left>
        
        <Right>
          <RightContainer>
          <RightLeftDiv>
          Contact: <br/>
          contact@brender.it
            </RightLeftDiv>
          <RightRightDiv>
            Follow us: 
            <Icon icon={faFacebook} />
            <Icon icon={faTwitter} />
            <Icon icon={faInstagram} />
            <Icon icon={faYoutube} />
            <Icon icon={faLinkedin} />
            </RightRightDiv>
            </RightContainer>
        </Right>
      </Container>
    </Section>
    
  )
}



export default Footer;