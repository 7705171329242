import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { WistiaProvider,WistiaPlayer } from "@wistia/react-embeds"
import styled from "styled-components"

const Section = styled.div`
  height: 100vh;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1679D8;
`;


const Title = styled.h1`
  font-size: 48px;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  background-color: #F0F1F3;
  margin: 20px;
  width: 950px;

  @media only screen and (max-width: 768px) {
    width: auto;
    font-size: 28px;
  }
`;

const StyledCarousel = styled(Carousel)`
  width: 60%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -webkit-box-shadow: 0px 1px 8px -1px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 1px 8px -1px rgba(66, 68, 90, 1);
  box-shadow: 0px 1px 8px -1px rgba(66, 68, 90, 1);

    @media only screen and (max-width: 768px) {
    width: 90%;
    
  }
`;


const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

function Projects() {
  return (
    <div id="section4">
    <Section>
      <Title>Check examples projects and pricing</Title>
      <StyledCarousel showThumbs={false}>
        <WistiaProvider>
            <WistiaPlayer hashedId="hkqecdusen" />
            <StyledDiv>
              <p className="legend">Legend 1</p>
            </StyledDiv>
        </WistiaProvider>
        <WistiaProvider>
            <WistiaPlayer hashedId="hkqecdusen" />
            <StyledDiv>
              <p className="legend">Legend 2</p>
            </StyledDiv>
        </WistiaProvider>
        <WistiaProvider>
            <WistiaPlayer hashedId="hkqecdusen" />
            <StyledDiv>
              <p className="legend">Legend 2</p>
            </StyledDiv>
        </WistiaProvider>    
      </StyledCarousel>  
      </Section>  
      </div>
  );
}



export default Projects;