import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function UploadFinished(props) {

    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Successfuly uploaded file to the service
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4>Check your inbox</h4>
            <p>We have sent a confirmation to your email: <b>{props.email}</b></p>
            <p>Your order id: <b>{props.taskId}</b></p>
            
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
        </Modal>
    );
}

export default UploadFinished
