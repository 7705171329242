import React from 'react'
import styled from "styled-components"
import Nav from './Nav'
import ChunkedUpload from '../views/ChunkedUpload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck} from '@fortawesome/free-solid-svg-icons';




const Section = styled.div`
  height: 100vh;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
 

  @media only screen and (max-width: 768px) {
    scroll-snap-align: none;
    height: 200vh;
  }
`;

const Container = styled.div`
  width: 1400px;
  display: flex;
  justify-content: space-between;


  @media only screen and (max-width: 768px) {
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  }
`;

const Left = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  color: #183153;
  background-color: #F0F1F3;
  

  @media only screen and (max-width: 768px) {
    flex: 1;
    align-items: center;
  }
`;

const Title = styled.h1`
  font-size: 62px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  float: left;
  color: #183153;
  background-color: #F0F1F3;
  border-radius: 25px 0px 0px 25px;
  margin-bottom: -35px;


  @media only screen and (max-width: 768px) {
    font-size: 54px;
    text-align: center;
    margin: 0;
  }
`;

const WhatWeDo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;


const SubTitle2 = styled.h2`
  color: #58dd51;
  font-weight: bold;
  font-family: sans-serif;
  font-size: 20px;
  border-bottom: 3px solid #bfbfbf;

  animation: 3s anim-flipX ease infinite;

@keyframes anim-flipX {
  0% {
    opacity: 0;
    transform: rotateX(90def);
  }
  50% {
    opacity: 1;
    transform: rotateX(360deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(360deg);
  }
}
  
`;

const Desc = styled.p`
  font-size: 24px;
  color: #183153;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
    padding: 20px;
    text-align: center;
  }
`;


const Button = styled.button`
  background-color: #EA7600;
  color: #363636;
  font-weight: 500px;
  width: 150px;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;

  -webkit-box-shadow: 0px 4px 3px 0px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 4px 3px 0px rgba(66, 68, 90, 1);
  box-shadow: 0px 4px 3px 0px rgba(66, 68, 90, 1);

  &:hover {
    background-color: #0069d9;
    -webkit-box-shadow: 0px 4px 3px -3px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 4px 3px -3px rgba(66, 68, 90, 1);
    box-shadow: 0px 4px 3px -3px rgba(66, 68, 90, 1);
    }
`;

const Stripe = styled.img`
  height: 40px;
  padding-top: -10px;
`;

const Right = styled.div`
  flex: 3;
  position: relative;
  color: #183153;
  background-color: #F0F1F3;
  border-radius: 25px 25px 25px 25px;
  margin-top: 46px;
  margin-bottom: 50px;

  -webkit-box-shadow: 3px 3px 5px 2px rgba(66, 68, 90, 1);
  -moz-box-shadow: 3px 3px 5px 2px rgba(66, 68, 90, 1);
  box-shadow: 3px 3px 5px 2px rgba(66, 68, 90, 1);

  @media only screen and (max-width: 768px) {
    flex: 1;
    width: 100%;
  }
  
`;

const LogoDiv = styled.div`
  position: relative;
  display: inline-block;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
`;

const Text = styled.p`
  font-size: 20px;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-left: 10px;
  color: #183153;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 24px;
  color: #183153;
`;


function Hero() {


  const handleButtonClick = () => {
    const section2 = document.getElementById('section2');
    section2.scrollIntoView({ behavior: 'smooth' });
  };


  return (
    <Section>
      <Nav />
      <Container>
        <Left>
          <LogoDiv>
          <Title><b>BRENDER.IT</b></Title>
          </LogoDiv>  
          
          <Desc>Blender Render Farm - Fast and Affordable Rendering Services</Desc>
          <WhatWeDo>
            
            <SubTitle2>Payments Powered by <Stripe src="./img/stripe.png" /></SubTitle2>
          </WhatWeDo>
          <div class="alert alert-warning" role="alert">
              Warning! Only <b>CYCLES</b> Engine Supported! The project will be rendered using cycles engine even if you set eevee.
          </div>
          <List>
            <ListItem>
              <Icon icon={faCheck} />
              <Text>Know the price before rendering.</Text>
            </ListItem>
            <ListItem>
              <Icon icon={faCheck} />
              <Text>No registration needed, just send your project.</Text>
            </ListItem>
            <ListItem>
              <Icon icon={faCheck} />
              <Text>Pay with a payment gateway using a credit card or other method.</Text>
            </ListItem>
          </List>
          <Button onClick={handleButtonClick}>Read More</Button>
        </Left>
        <Right>
          {/* <SubTitle>Upload .file blend to render</SubTitle> */}
          <ChunkedUpload />         
        </Right>
      </Container>
    </Section>
    
  )
}



export default Hero;