import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import Hero from "./components/Hero";
import Howto from "./components/Howto";
import ContactForm from "./components/ContactForm";
import styled from "styled-components";
import Steps from "./components/Steps";
import Projects from "./components/Projects";
import Footer from "./components/Footer";
import Terms from "./components/Terms";
import PrivatyPolicy from "./components/PrivatyPolicy";
import Hardware from "./components/Hardware";
import ReactGA from 'react-ga';
  

const TRACKING_ID = "G-THJ6Z1PSRV"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);



const Container = styled.div`
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  z-index: 1;
  height: 100vh;
  overflow: scroll;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y proximity;
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar{
    display: none;
  }
  color: #183153;
  background-color: #F0F1F3;

  @media (max-width: 768px) {
    overflow: auto;
    overflow-x: hidden;
    scroll-snap-points-y: none;
    scroll-snap-type: none;
    height: auto;
  }

`;


function App() {


  return (
    <Container>
      <Router>
        <AuthProvider>
          <Switch>
          <Route exact path="/">
            <Hero />
            <Steps/>
              <Howto />
              <Hardware />
            <Projects />
            <ContactForm />
            <Footer />
              </Route>
              <Route path="/terms"> 
                <Terms />
            </Route>
            <Route path="/privatypolicy">
                <PrivatyPolicy />
            </Route>
            </Switch>
        </AuthProvider>
      </Router>
    </Container>
    
  );
}

export default App;