import React from 'react'
import styled from "styled-components"
import { WistiaProvider,WistiaPlayer } from "@wistia/react-embeds"

const Section2 = styled.div`
  height: 100vh;
  scroll-snap-align: center;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: 1400px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    width: 100%;
  }
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding-right: 50px;

  @media (max-width: 768px){
    order: 2;
    flex: 2;
    padding: 0;
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding-left: 50px;
  background-color: #F4F4F2;
  border: #251749;
  border-radius: 15px;
  margin-top: 50px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Title = styled.h1`
  font-size: 74px;
  color: #495579;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  @media (max-width: 768px) {
    text-align: center;
  }

`;
const WhatWeDo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const SubTitle = styled.h2`
  color: #ae6300;  
`;
const Desc = styled.p`
  font-size: 24px;
  color: #3e3e3e;
  
  @media (max-width: 768px) {
    text-align: center;
  }
`;
const Button = styled.button`
  background-color: #EA7600;
  color: #363636;
  font-weight: 500px;
  width: 150px;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0069d9;
    -webkit-box-shadow: 0px 4px 3px -3px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 4px 3px -3px rgba(66, 68, 90, 1);
    box-shadow: 0px 4px 3px -3px rgba(66, 68, 90, 1);
    }
`;

const VideoContainer = styled.div`
-webkit-box-shadow: 4px 7px 5px 2px rgba(66, 68, 90, 1);
-moz-box-shadow: 4px 7px 5px 2px rgba(66, 68, 90, 1);
box-shadow: 4px 7px 5px 2px rgba(66, 68, 90, 1);
  transform: translateZ(2px);
`;

function Howto() {
  const handleButtonClick = () => {
    const section2 = document.getElementById('section4');
    section2.scrollIntoView({ behavior: 'smooth' });
  };
  

  return (
    <div id="section3">
    <Section2>
      <Container>
        <Left>
          <VideoContainer>
            <WistiaProvider>
              <WistiaPlayer hashedId="hkqecdusen"></WistiaPlayer>
              </WistiaProvider>
          </VideoContainer>  
        </Left>
        <Right>
          <Title>Design. Upload. Render.</Title>
          <WhatWeDo>
            <SubTitle>What we Do</SubTitle>
          </WhatWeDo>
          <Desc>We enjoy rendering fast and cheap Blender projects.</Desc>
          <Button onClick={handleButtonClick}>Check examples</Button>
        </Right>
      </Container>
      </Section2>
      </div>
    
  )
}



export default Howto;