import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import AuthContext from "../context/AuthContext";

const Section = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    display: none;
  }
  
`;

const Container = styled.div`
  width: 1400px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  background-color: #20232A;
  border-radius: 10px;
  
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  color: #e8e8e8;
  font-family:cera-round-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-right: 20px;
`;

const LoginInput = styled.input`
  border: 1px solid ;
  border-radius: 5px;
  padding: 5px;
  font-family: monospace;
  color: #20232A;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
  
`;

const LoginButton = styled.button`
  font-family: monospace;
  color: #495579;
  background-color: #20232A;
  border: 1px solid #495579;
  border-radius: 5px;
  padding: 5px 15px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Logo = styled.img`
  height: 50px;
  padding-left: 20px;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  list-style: none;
  padding-top: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 20px;
  }
`;


const ListItem = styled.li`
  font-family: cera-round-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  cursor: pointer;
  &:hover {
    border-radius: 5px;
    background-color: #495579;
  }

  @media screen and (max-width: 768px) {
    color: #e8e8e8;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
`;


const Nav = () => {
  const { logoutUser, loginUser } = useContext(AuthContext);
  const [loggedIn, setLoggedIn] = useState(false);

  const handleListItemClick = (id) => {
    const section2 = document.getElementById(id);
    section2.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const username = form.login.value;
    const password = form.password.value;

    if (form.checkValidity() === true) {
      loginUser(username, password);
      setLoggedIn(true);
    }
  };

  const handleLogout = () => {
    logoutUser();
    setLoggedIn(false);
  };

  return (
    <Section>
      <Container>
        <Links>
          <Logo src="./img/iconT.png" />
          <List>
            <ListItem><a href="https://brender.it">Home</a></ListItem>
            <ListItem onClick={() => handleListItemClick("section2")}>How To</ListItem>
            <ListItem onClick={() => handleListItemClick("section3")}>About</ListItem>
            <ListItem onClick={() => handleListItemClick("section4")}>Projects</ListItem>
            <ListItem onClick={() => handleListItemClick("section5")}>Contact</ListItem>
            
          </List>
        </Links>
        <LoginContainer>
          {loggedIn ? (
            <LoginButton onClick={handleLogout}>Log out</LoginButton>
          ) : (
            <form onSubmit={handleSubmit}>
              <LoginInput type="text" placeholder="Login" name="login" required />
              <LoginInput type="password" placeholder="Password" name="password" required />
              <LoginButton type="submit">Log In</LoginButton>
            </form>
          )}
        </LoginContainer>
      </Container>
    </Section>
  );
};

export default Nav;