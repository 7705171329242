import React from "react";
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const Section = styled.div`
    width: 1200px;
    padding: 30px;
`;

const Title = styled.h1`
    font-size: 24px;
    font-weight: bold;
`;

const Subtitle = styled.h2`
    font-size: 20px;
    font-weight: bold;
`;

const Paragraph = styled.p`
    font-size: 16px;
`;

const TextWrapper = styled.div`
    margin: 20px 0;
`;


const PrivatyPolicy = () => {
    return (
        <Section>
          <Button href="https://brender.it" variant="primary" size="lg">
            Back to site
          </Button>
        <Title>Last Modified: November 8, 2022</Title>
        <Paragraph>
          Data Controller Brender IT Henryka Sienkiewicza 8/2, 55-080 Krzeptów Owner contact email: contact@brender.it

          This Privacy Policy provides our policies and procedures for collecting, using, and disclosing your information. Users can access the BRENDER IT Render Service (the "Service") through our website www.brender.it. All of the different forms of data, content, and information described below are collectively referred to as “information.” By using the Service, you consent to this Privacy Policy.
        </Paragraph>
        <TextWrapper>
          <Subtitle>
            1. What information do we collect?
          </Subtitle>
          <Paragraph>
            We collect information from you when you submit a render job or when contacting us for information.

            Information You Provide. When submitting a render job on our site, as appropriate, you may be asked to enter your e-mail address. You may, however, visit our site anonymously.

            Digital Files. We collect and store the files you upload using our Service. The files are stored on a secure online storage system and will be erased automatically after 7 days. To protect your intellectual property, unless technical problems occur regarding your render project, or when you ask us to do so, your source files will not be accessed by us manually.
          </Paragraph>
        </TextWrapper>
        <TextWrapper>
          <Subtitle>
            2. What do we use your information for?
          </Subtitle>
          <Paragraph>
            Any of the information we collect from you may be used in one of the following ways:

            To process transactions. Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested.
                        
            The email address you provide for order processing, may be used to send you information and updates pertaining to your order.

            Google Analytics (Google Inc.) Google Analytics is a web analysis service provided by Google Inc. (“Google”). Google utilizes the Data collected to track and examine the use of this Website, to prepare reports on its activities and share them with other Google services. Google may use the Data collected to contextualize and personalize the ads of its own advertising network. Personal Data collected: Cookies and Usage Data. Place of processing: US – Privacy Policy – Opt Out.

            Google AdWords conversion tracking (Google Inc.) Google AdWords conversion tracking is an analytics service provided by Google Inc. that connects data from the Google AdWords advertising network with actions performed on this Website. Personal Data collected: Cookies and Usage Data. Place of processing: US – Privacy Policy

            Facebook Ads conversion tracking (Facebook, Inc.) Facebook Ads conversion tracking is an analytics service provided by Facebook, Inc. that connects data from the Facebook advertising network with actions performed on this Website. Personal Data collected: Cookies and Usage Data. Place of processing: US – Privacy Policy.

            Twitter Ads conversion tracking (Twitter, Inc.) Twitter Ads conversion tracking is an analytics service provided by Twitter, Inc. that connects data from the Twitter advertising network with actions performed on this Website. Personal Data collected: Cookies and Usage Data. Place of processing: US – Privacy Policy.

            To report a a violation of GDPR principles that affected you, please contact us at contact@brender.it.We will resolve the issue if possible, and prevent any future occurences of the same or similar violations.
          </Paragraph>
        </TextWrapper>
        <TextWrapper>
          <Subtitle>
            3. How do we protect your information?
          </Subtitle>
          <Paragraph>
            We implement a variety of security measures to maintain the safety of your personal information when you place an order, enter, submit, or access your personal information.

            We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential.

            After a transaction, your private information (credit cards, social security numbers, financials, etc.) will not be stored on our servers, but instead managed and processed by a Level 1 PCI compliant payment processor, Stripe.
          </Paragraph>
        </TextWrapper>
        <TextWrapper>
          <Subtitle>
            4. Do we use cookies?
          </Subtitle>
          <Paragraph>
            We use cookies for the functionality of the web application, as well as to give the user a better and more streamlined browsing experience. More information about our usage of cookies is available in our cookie policy.
          </Paragraph>
        </TextWrapper>
        <TextWrapper>
          <Subtitle>
            5. Do we disclose any information to outside parties?
          </Subtitle>
          <Paragraph>
            We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
          </Paragraph>
        </TextWrapper>
        <TextWrapper>
          <Subtitle>
            6. Childrens Online Privacy Protection Act Compliance
          </Subtitle>
          <Paragraph>
            We are in compliance with the requirements of COPPA (Childrens Online Privacy Protection Act), we do not collect any information from anyone under 13 years of age. Our website, products and services are all directed to people who are at least 13 years old or older.
          </Paragraph>
        </TextWrapper>
        <TextWrapper>
          <Subtitle>
            7. Online Privacy Policy Only
          </Subtitle>
          <Paragraph>
            This online privacy policy applies only to information collected through our website and not to information collected offline.
          </Paragraph>
        </TextWrapper>
        <TextWrapper>
          <Subtitle>
            8. Contacting Us
          </Subtitle>
          <Paragraph>
            If you have any questions about this Privacy Policy, please contact us at contact@brender.it.
          </Paragraph>
        </TextWrapper>
        <TextWrapper>
          <Subtitle>
            9. Changes to our Privacy Policy
          </Subtitle>
          <Paragraph>
            This Privacy Policy may change from time to time. If we make a change to this privacy policy that we believe materially reduces your rights, we will provide you with notice (for example, by email). And we may provide notice of changes in other circumstances as well. By continuing to use the Service after those changes become effective, you agree to be bound by the revised Privacy Policy.
          </Paragraph>
        </TextWrapper>
        </Section>
  );
};

export default PrivatyPolicy;
