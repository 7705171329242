import React from 'react'
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles, faUpload, faCreditCardAlt, faDownload} from '@fortawesome/free-solid-svg-icons';


const Icon = styled(FontAwesomeIcon)`
  font-size: 24px;
  color: #FFFBEB;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;


const Section = styled.div`
  height: 100vh;
  scroll-snap-align: center;
  background-color: #E8E8E8;
  border-radius: 100px;

  @media only screen and (max-width: 768px) {
    height: auto;
  }
`;

const Title = styled.h1`
  border-top: 200px;
  font-size: 4rem;
  margin-bottom: 1rem;
  text-align: center;
  padding-top: 40px;
  background-color: #E8E8E8;
  color: #495579;
`;

const StepsContainer = styled.div`
  width: 100%;
  height: 30%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  margin-top: 2rem;
  align-items: center;
  justify-content: flex-end;
  padding-left: 50px;
  padding-right: 50px;
  z-index: 0;

  
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }
`;

const Step = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #495579;
  border-radius: 15px;
  height: 450px;
  position: relative;

  filter: drop-shadow(3px 3px 6px #000);

  @media only screen and (max-width: 768px) {
    padding: 20px;
    width: auto;
    margin-bottom: 0;
    height: 200px;
  }
`;

const StepTitle = styled.h2`
  font-size: 2.2rem;
  margin-bottom: 1rem;
  background-color: #495579;
  color: #FFFBEB;
  border-bottom: 3px solid #bfbfbf;
  border-top: 3px solid #bfbfbf;

  @media only screen and (max-width: 768px) {
    font-size: 25px;
  }
`;

const StepDescription = styled.p`
  font-size: 1rem;
  text-align: center;
  background-color: #495579;
  color: #FFFBEB;

  @media only screen and (max-width: 768px) {
    text-align: justify;
    font-size: 15px;
  }
`;

const Image = styled.img`
  width: 200px;
  height: 250px;
  object-fit: contain;
  border-radius: 10px;
  transition: transform 0.3s ease;
  -webkit-box-shadow: 2px 2px 14px -1px rgba(66, 68, 90, 1);
  -moz-box-shadow: 2px 2px 14px -1px rgba(66, 68, 90, 1);
  box-shadow: 2px 2px 14px -1px rgba(66, 68, 90, 1);
  position: relative;
  z-index: 0;
  

  &:hover {
  transform: scale(2);
  -webkit-box-shadow: 2px 2px 14px -13px rgba(66, 68, 90, 1);
  -moz-box-shadow: 2px 2px 14px -13px rgba(66, 68, 90, 1);
  box-shadow: 2px 2px 14px -13px rgba(66, 68, 90, 1);
  z-index: 1;
}

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;



function Steps() {
  return ( 

    <div id="section2">
    <Section>
          <Title>How it Works?</Title>
      <StepsContainer>

        <Step>
          <StepTitle><Icon icon={faWandMagicSparkles} /> STEP 1</StepTitle>
          <StepDescription><b>Prepare</b> your project pack and <b>Save As project.blend</b></StepDescription>             
          <Image src="./img/sshot1.png"alt="Step 1" />  
        </Step>

        <Step>
          <StepTitle><Icon icon={faUpload} /> STEP 2</StepTitle>
          <StepDescription><b>Upload</b> your file .blend to our server. Use the form</StepDescription>
          <Image src='./img/animation.gif' alt="Step 2" />
        </Step>

        <Step>
          <StepTitle><Icon icon={faCreditCardAlt} /> STEP 3</StepTitle>
          <StepDescription>Check inbox for <b>price</b> and time of service. Pay to start rendering</StepDescription>
          <Image src='./img/payment.png' alt="Step 3" />
        </Step>

        <Step>
          <StepTitle><Icon icon={faDownload} /> STEP 4.</StepTitle>
          <StepDescription><b>Download</b> already rendered images. Link to download will be send by email</StepDescription>
          <Image src='./img/zz.png' alt="Step 4" />
        </Step>

      </StepsContainer>
      </Section>
      </div>
  )
}

export default Steps;