const configName = process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : "prod"


const configs = {
    "local": {
        "apiUrl": "http://localhost:8000"
    },
    "prod": {
        "apiUrl": "https://brender.it"
    }
}

export const {apiUrl} = configs[configName]
