import React from 'react'
import styled from "styled-components"

const Section2 = styled.div`
  height: 100vh;
  scroll-snap-align: center;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
    height: auto;
  }
`;

const Container = styled.div`
  width: 1400px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    display: block;
    width: auto;
    margin-bottom: 30px;
  }
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding-right: 50px;

  @media only screen and (max-width: 768px) {
   padding: 0; 
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding-left: 50px;
  background-color: #F4F4F2;
  border: #251749;
  border-radius: 15px;
  margin-top: 50px;
  margin-bottom: 50px;

  @media only screen and (max-width: 768px) {
    padding-left: 20px;
    display:block;
    margin-top: -100px;
  }
`;

const Title = styled.h1`
  font-size: 74px;
  color: #495579;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  @media only screen and (max-width: 768px) {
    font-size: 48px;
  }
`;
const WhatWeDo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const SubTitle = styled.h2`
  color: #ae6300;  
`;
const Desc = styled.p`
  font-size: 24px;
  color: #3e3e3e;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

const Image = styled.img`
  max-width: 600px;

  @media only screen and (max-width: 768px) {
    max-width: 300px;
  }
`;

function Hardware() {

  return (
    <div id="section6">
    <Section2>
      <Container>
        
        <Right>
          <Title>Geforce RTX 4090</Title>
          <WhatWeDo>
              <SubTitle>CUDA CORES: 16 384</SubTitle>
              
          </WhatWeDo>
          <Desc>We use the best components for rendering, RTX 4090 graphics cards provide everything you need for fast and efficient rendering.</Desc>
          
          </Right>
          <Left>
          <ImageContainer>
            <Image src='./img/geforce.png.webp'></Image>
          </ImageContainer>  
        </Left>
      </Container>
      </Section2>
      </div>
    
  )
}



export default Hardware;